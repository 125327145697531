<template>
  <v-app id="404">
    <v-container fluid fill-height>
      <v-layout align-center justify-center row>
        <div class="text-md-center">
          <h1>404</h1>
          <h2 class="my-3 headline ">Sorry, page not found</h2>
        </div>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'pageNotFound',
}
</script>

<style scoped>
  h1 {
    font-size: 150px;
    line-height: 150px;
    font-weight: 700;
    color: #5271ff;
  }
  h2 {
    color: #5271ff;
  }
</style>
